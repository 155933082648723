import React from 'react';
import { IPPFourthLevelHeading, IPPParagraph } from '../library/Heading/Heading';
import useTimeOfDay from '../hooks/useTimeOfDay';

interface Props {
  name: string;
  group?: string;
  isBtAdmin?: boolean;
  isAdmin?: boolean;
}

const UserGreeting = ({ name, group, isBtAdmin, isAdmin }: Props) => {
  const timeOfDay = useTimeOfDay();
  const userType = isBtAdmin
    ? 'Indigo Partner Portal | BT Admin Dashboard'
    : isAdmin
    ? 'Indigo Partner Portal | Admin Dashboard'
    : 'Indigo Partner Portal | User Dashboard';
  const userGreeting = isBtAdmin
    ? `${timeOfDay}, ${name}`
    : `${timeOfDay}, ${name} - (Organisation ${isAdmin ? 'Admin' : 'User'})`;
  return (
    <div className=" z-0">
      <div className="pt-[30px] pr-[20px] pl-[20px]">
        <div className="p-0 pb-[30px] rounded-[5px] mx-0 flex flex-wrap">
          <div className="p-0 w-full">
            <IPPFourthLevelHeading
              headerText={userGreeting}
              className={'text-black text-[22px] mb-0.5 leading-[1.2] capitalize'}
            />
            {group && (
              <IPPFourthLevelHeading
                headerText={`Organisation - ${group}`}
                className={'text-black text-[22px] mb-0.5 leading-[1.2]'}
              />
            )}
            <IPPParagraph headerText={userType} className={'mb-0 text-[12px] mt-0 block'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGreeting;
