import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Search from '../Search';
import IUserList from '../../utils/UserList/UserList.interface';
import ReactLoading from 'react-loading';
import { CreateUserForm } from './CreateUser';
import { IPPHeading, IPPParagraph } from '../../library/Heading/Heading';
import UserRow from './UserRow';
import ConditionalLoader from '../Hoc/ConditionalLoader';

/**
 * This common props takes in several parameters.
 * @data - These prop is likely used to store the modules list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @setQuery - These prop is used to update the @query based on user's input
 * @numValuesToShow - This prop is used ti implement show functionality based on selected number of data.
 * @handleNumValuesChange - This prop is used to update the @numValuesToShow base on user's input.
 * @createUser - Dummy function to create user.
 * @valuesToShoe - Store the @numValuesToShow data.
 **/
interface UserListProp {
  isLoading: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  users: IUserList[];
  email: string;
  partnerName: string;
  onDeleteUser: (email: string) => void;
  onEditUser: (user: IUserList) => void;
  onUserCreated?: () => void;
}
// React functional component that displays a list of Admin users in a table format with a search bar and a dropdown to change the number of users to display.
const UserList: React.FC<UserListProp> = ({
  isLoading,
  query,
  setQuery,
  users,
  email,
  partnerName,
  onUserCreated,
  onEditUser,
  onDeleteUser
}) => {
  const orgEmail = sessionStorage.getItem('userEmail');
  const partnerCompanyName = sessionStorage.getItem('companyName');
  const partnerDomainList = sessionStorage.getItem('domainList');
  const orgDomain = partnerDomainList != null ? JSON.parse(partnerDomainList) : [];

  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        <div className="bg-white block" style={{ paddingInline: '2px 2px', width: '100%' }}>
          <div className="flex justify-between">
            <IPPHeading
              headerText={'Organisation Users'}
              className={'text-black text-xl leading-tight font-medium mb-0'}
            />
            <CreateUserForm
              organisationEmail={orgEmail}
              organisationName={partnerCompanyName}
              organisationDomain={orgDomain}
              callback={onUserCreated}
            />
          </div>
          <br />
          <hr />
          <br />
          <Search onChange={(e: any) => setQuery(e.target.value)} text="Search by name..." />
          {/* Todo - fix with next release */}
          {/* <Show value={numValuesToShow} onChange={handleNumValuesChange} /> */}
          <br />
          <br />
          <ConditionalLoader isLoading={isLoading} width={900}>
            <div className="w-full max-h-96 overflow-y-auto pb-4">
              {/* If data is available, display the table */}
              <table className="mx-auto min-w-fit max-w-4xl w-auto md:w-full table-fixed whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                <thead className="bg-[#f8f8f8] min-w-fit">
                  <tr className="text-black text-center">
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Display Name{' '}
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Email{' '}
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Role{' '}
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Action{' '}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr className="text-light-gray">
                    <td className="px-6 py-4 text-center">
                      {' '}
                      <IPPParagraph headerText={`${partnerName}`} className={''} />
                    </td>
                    {email === null ? (
                      <td className="px-6 py-4 text-center hidden">
                        {' '}
                        <IPPParagraph headerText={`${orgEmail}`} className={''} />
                      </td>
                    ) : (
                      <td className="px-6 py-4 text-center ">
                        {' '}
                        <IPPParagraph headerText={`${orgEmail}`} className={''} />
                      </td>
                    )}
                    <td className="px-6 py-4 text-center ">
                      {' '}
                      <IPPParagraph headerText={'Admin'} className={''} />
                    </td>
                  </tr>
                  {/* data is mapped into db and the values are stored of type IUserList. Here the index is of type number which generally determines the key value */}
                  {users
                    .sort((a, b) => a.name!.localeCompare(b.name!))
                    .filter((user) => user.name!.toLowerCase().includes(query.toLowerCase()))
                    .map((user: IUserList, index: number) => (
                      <UserRow
                        key={index}
                        user={user}
                        onDeleteUser={onDeleteUser}
                        onEditUser={onEditUser}
                        disabled={false}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </ConditionalLoader>
        </div>
      </div>
    </Fragment>
  );
};

export default UserList;
