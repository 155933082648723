import React, { Fragment, useContext, useEffect, useState } from 'react';
import { LoginContext } from '../context/IppContext';
import { FederatedIdentity } from '../utils/AccessCredentials/FederatedIdentity';
import { downloadFile } from '../utils/Helper/downloadFiles';
import Downloadfile from '../library/DownloadFiles/DownloadFile';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import OrgUser from '../utils/OrgUser/OrgUser';
import ModuleGrid from '../components/UserPanel/ModuleGrid';
import AwsLogin from '../utils/AccessCredentials/AwsLogin';
import { AWS_SIGNIN_URL } from '../utils/Helper/helper';
import Button from '../library/Button/button';

const UserPanel: React.FC<any> = () => {
  // useState hook to store the timeOfDay state variable
  const { loginToken } = useContext(LoginContext);
  const [accessKey, setAccessKey] = useState<String>('');
  const [secret, setSecret] = useState<String>('');
  const [session, setSession] = useState<String>('');
  const [roleARN, setRoleArn] = useState<String>('');
  const [error, setError] = useState<string>(''); // New error state
  const parsedLoginToken = JSON.parse(loginToken);
  const userPoolId = parsedLoginToken.userPoolId;
  const identityPoolId = parsedLoginToken.IdentityPoolId;
  const federatedIdentity = new FederatedIdentity({
    userPoolId,
    identityPoolId
  });
  const [moduleList, setModuleList]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [isShowCred, setIsShowCred] = useState<boolean>(false);
  // To handle show functionality
  const [numValuesToShow, setNumValuesToShow] = useState('');
  // To handle change based on event on show bar.
  const handleNumValuesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setNumValuesToShow(value);
  };
  const navigate: NavigateFunction = useNavigate();
  //   Storing the user's name and displaying it as a welcome page
  const userEmail = sessionStorage.getItem('userEmail') as string;

  // Download UserGuideBook and script.
  const downloadUserGuide = () => {
    const fileUrl = '/UserGuideBook.pdf';
    const fileName = 'UserGuideBook'; // Set the desired file name
    downloadFile(fileUrl, fileName);
  };
  const downloadFileList = [
    { fileUrl: '/setup.sh', fileName: 'setup.sh' },
    { fileUrl: '/ipp_update_script.sh', fileName: 'ipp_update_script.sh' }
  ];
  const downloadScript = () => {
    for (const file of downloadFileList) {
      downloadFile(file.fileUrl, file.fileName);
    }
  };
  // This function is used to fetch federated session from backend and save it in component state
  const fetchFederatedSession = async () => {
    try {
      const idToken = parsedLoginToken.cognitoDetails.message.idToken.jwtToken;
      const role =
        parsedLoginToken.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const responseData = await federatedIdentity.fetchAWSCredentials(idToken, userEmail, role);
      setAccessKey(responseData.body.data.Credentials?.AccessKeyId ?? '');
      setSecret(responseData.body.data.Credentials?.SecretAccessKey ?? '');
      setSession(responseData.body.data.Credentials?.SessionToken ?? '');
      setRoleArn(role);
      setIsShowCred(true);
    } catch (error) {
      setError('JSON parsing error: ' + error); // Handle JSON parsing error
    }
  };
  // this function is used to get sign-in token from backend and send it to aws federetion endpoint for console login.
  const getCredentials = async () => {
    try {
      const idToken = parsedLoginToken.cognitoDetails.message.idToken.jwtToken;
      const role =
        parsedLoginToken.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const responseData = await federatedIdentity.fetchAWSCredentials(idToken, userEmail, role);
      const awsLoginObj = new AwsLogin({
        accessKey: responseData.body.data.Credentials?.AccessKeyId,
        secret: responseData.body.data.Credentials?.SecretAccessKey,
        session: responseData.body.data.Credentials?.SessionToken
      });
      const consolLoginStatus = await awsLoginObj.consoleLogin();
      if (consolLoginStatus.statusCode === 201) {
        const SigninToken = consolLoginStatus.body.SigninToken;
        // construct the url with signin token for aws login
        const getLoginUrl = `${AWS_SIGNIN_URL}${SigninToken}`;
        window.open(getLoginUrl, '_blank', 'noopener,noreferrer');
      }
    } catch {
      setError('error: in aws console login' + error); // Handle console login error
    }
  };

  // function to clear storage when back or forward button is clicked from browser
  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      sessionStorage.clear();
      navigate('/login');
    });
  }, []);

  useEffect(() => {
    const getUserData = async (email: string) => {
      try {
        const userObj = new OrgUser({
          email: userEmail ?? ''
        });

        // API responses have been merged since actions are same
        const userData = await userObj.getUserDetails();
        setModuleList(userData.body.moduleList);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    void getUserData(userEmail ?? '');
  }, []);

  return (
    <Fragment>
      <div className="overflow-hidden relative block">
        <Downloadfile onGuideBook={downloadUserGuide} onScript={downloadScript} />
        <div className="flex flex-col justify-start mr-[10px] my-[6px] pt-1 px-4 border border-black-500 rounded-[5px] ">
          <Button
            onclick={getCredentials}
            className="bg-transparent w-48 hover:bg-blue-500 text-blue-700 font-semibold hover:text-black mt-2 py-2 px-2 border border-black-500 hover:border-solid rounded"
            buttonText="View in CodeCommit"
          />
          <Button
            onclick={fetchFederatedSession}
            className="bg-transparent w-48 hover:bg-blue-500 text-blue-700 font-semibold hover:text-black mt-2 py-2 px-2 border border-black-500 hover:border-solid rounded"
            buttonText="Get AWS Cred."
          />
          &nbsp;
          {isShowCred && (
            <>
              <h4
                id="aceeskeyheader"
                className="text-black text-[16px] mb-1 leading-[1.2] font-bold"
              >
                AWS Credentials
              </h4>
              <h4 id="aceeskeyheader" className="text-black text-[16px] mb-0.5 leading-[1.2]">
                aws_access_key_id: {accessKey}
              </h4>
              <h4 id="aceeskeyheader" className="text-black text-[16px] mb-0.5 leading-[1.2]">
                aws_secret_access_key: {secret}
              </h4>
              <p
                id="aceeskeyheader"
                className="text-black text-[16px] mb-0.5 leading-[1.2] break-words"
              >
                aws_session_token: {session}
              </p>
              <h4 id="aceeskeyheader" className="text-black text-[16px] mb-0.5 leading-[1.2]">
                role_arn: {roleARN}
              </h4>
            </>
          )}
        </div>
        <div className="flex flex-col justify-start mr-[10px] my-[10px]  p-4 border border-black-500 rounded-[5px] bg-offwhite">
          <ModuleGrid
            key={moduleList.key}
            data={moduleList}
            isLoading={isLoading}
            query={query}
            setQuery={setQuery}
            numValuesToShow={numValuesToShow}
            handleNumValuesChange={handleNumValuesChange}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default UserPanel;
