import React, { useState, useEffect, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import Button from '../library/Button/button';
import { UserType } from '../utils/Helper/enums';
import UserLogin from '../utils/UserLogin/UserLogin';
import WelcomeHeader from '../components/TopBar/WelcomeHeader';
import { LoginContext } from '../context/IppContext';
import { encrypt } from '../utils/Helper/encryption';
import { wrapAsyncFunction } from '../components/UtilityFunction/wrapAsyncFunction';
import Label from '../library/label/label';
import InputField from '../library/inputField/inputField';
import backgroundImage from './login-1200-800.jpeg';
import { adminRoutes, btAdminRoutes, routes } from '../routes/Routes';

const Login: React.FC<any> = (props: any) => {
  interface FormValues {
    email: string;
    password: string;
    token: string;
  }
  const [passwordShown, setPasswordShown] = useState(false);
  const [isPopupEnabled, setIsPopupEnabled] = useState(false);
  const [isCredentialInvalid, setCredentialInvalid] = useState(false);
  const [isNotVerified, setIsNotVerified] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { loginToken, updateToken } = useContext(LoginContext);
  const [visibility, setVisibility] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isTempLocked, setIsTempLocked] = useState(false);
  const message = isCredentialInvalid
    ? 'Email or Password is invalid'
    : isLocked
    ? 'Acount is Locked permanantly, please connect to BT Admin'
    : isTempLocked
    ? 'Account is temporarily Locked, Please try after 10 mins'
    : isNotVerified
    ? 'User is not verified'
    : 'Please wait';

  let isLoggedIn: boolean = false;
  const navigate: NavigateFunction = useNavigate();
  const togglePassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();

  // Toggle between show and hide button
  const buttonText = passwordShown ? 'Hide' : 'Show';

  // login method for users who want to sign-in to Organization user page
  const doLogin: SubmitHandler<FormValues> = async (data, event) => {
    event?.preventDefault();
    if (navigator.onLine) {
      setShowLoader(true);
      setTimeout(() => setShowLoader(false), 9000);
      const encryptedPassword = encrypt(data.password);
      const userLoginObj = new UserLogin({
        email: data.email || '',
        password: encryptedPassword || ''
      });

      sessionStorage.setItem('userPassword', encryptedPassword);
      setCredentialInvalid(false);
      const loginStatus = await userLoginObj.doLogin('');
      const responseData: any = loginStatus?.body ?? {};
      const userType = responseData?.userType ?? Number.MAX_SAFE_INTEGER;
      switch (loginStatus.statusCode) {
        case 201:
          setIsPopupEnabled(false);
          switch (userType) {
            case UserType.BtAdmin:
              navigate(btAdminRoutes.partnerAdmin);
              break;
            case UserType.OrganizationAdmin:
              isLoggedIn = true;
              updateToken(JSON.stringify(responseData));
              sessionStorage.setItem('secretCode', responseData.secretCode);
              sessionStorage.setItem(
                'accessToken',
                responseData.cognitoDetails.message.accessToken.jwtToken
              );
              sessionStorage.setItem('isMfaSetup', responseData.isMfaSetup);
              navigate(routes.termsAndConditions);
              break;
            case UserType.OrganizationUser:
              updateToken(JSON.stringify(responseData));
              sessionStorage.setItem('secretCode', responseData.secretCode);
              sessionStorage.setItem(
                'accessToken',
                responseData.cognitoDetails.message.accessToken.jwtToken
              );
              sessionStorage.setItem('isMfaSetup', responseData.isMfaSetup);
              navigate(routes.termsAndConditions);
              break;
            default:
              updateToken(JSON.stringify(responseData));
              break;
          }
          break;
        case 301:
          sessionStorage.setItem('isMfaSetup', 'true');
          navigate(routes.termsAndConditions);
          break;
        case 423:
          setIsPopupEnabled(true);
          setIsLocked(true);
          break;
        case 429:
          setIsPopupEnabled(true);
          setIsTempLocked(true);
          break;
        case 403:
          setIsNotVerified(true);
          setIsPopupEnabled(true);
          break;
        case 401:
          setCredentialInvalid(true);
          setIsPopupEnabled(true);
          break;
        case 500:
          alert('User Email is invalid');
          navigate(0);
          break;
        default:
          setCredentialInvalid(true);
          setIsPopupEnabled(true);
      }
      // Storing the login token and email address of the user
      sessionStorage.setItem('userEmail', data.email);
      sessionStorage.setItem('user-type', userType);
    } else {
      alert(
        'Sorry, there is not internet connection, available, please connect your device with a network.'
      );
    }
  };

  const popupCloseHandler = (e: boolean) => {
    sessionStorage.clear();
    updateToken('');
    navigate(0);
    setVisibility(e);
  };

  useEffect(() => {
    if (isLoggedIn && loginToken) {
      navigate(adminRoutes.dashoard);
    }
  }, [loginToken]);

  return (
    <div className="h-screen min-h-fit xl:max-h-screen relative overflow-hidden flex flex-col justify-center items-center p-0">
      {/* Header start */}
      <div className="w-full flex-grow-0">
        <Header>
          <WelcomeHeader />
        </Header>
      </div>
      {/* Header End */}
      {/* Border Start */}
      <div
        className="w-full p-0 z-50 mt-0 clear-both h-px"
        style={{
          borderTop: '1px solid #ccc'
        }}
      ></div>
      {/* Border End */}
      {/* Login Panel start */}
      <div className="flex-grow w-full relative p-0 flex flex-col justify-center items-center">
        <div className="relative flex-grow m-0 p-10 z-[5] flex justify-center items-center">
          <div className="min-h-[350px] min-w-[288px] w-72 sm:w-[20rem] max-w-[28rem] block pointer-events-auto sm:p-[0.5rem] rounded-lg shadow-lg bg-white">
            <form
              className="my-form p-4 pb-8"
              method="post"
              action=""
              name="frm_login"
              onSubmit={wrapAsyncFunction(handleSubmit(doLogin))}
            >
              {/* loginsection start */}
              <div className="w-full">
                <div className="text-[22px] text-center mb-[15px]">Log in</div>
                <Label htmlFor="validationCustomEmail" className="inline mb-0 mt-2">
                  {errors.email == null ? 'Email' : <span>Please enter your email address</span>}
                </Label>
                <InputField
                  id={'LoginEmail'}
                  className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                  type="email"
                  placeholder="This is usually your email address"
                  register={register('email', {
                    required: true
                  })}
                  style={
                    errors.email == null
                      ? { border: '1px solid #666' }
                      : { border: '1px solid #e60014' }
                  }
                />
              </div>
              {/* loginsection End */}
              <Label className="clear-left text-[#333] mt-1.5 border border-border-grey pb-2.5 hidden"></Label>
              <p className="mb-4 clear-both text-xs pb-0 mr-0 ml-0 mt-0"></p>
              <Label className="clear-left text-base inline mb-0 text-[#333] m-0 p-0 align-baseline">
                {errors.password == null ? 'Password' : <span>Please enter your password</span>}
              </Label>
              <Link
                className="pb-0 pt-1.5 cursor-pointer float-right text-[11px] text-black"
                onClick={(e) => togglePassword(e)}
                to={''}
              >
                {buttonText}
              </Link>
              <InputField
                id={'LoginPassword'}
                className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')]"
                type={passwordShown ? 'text' : 'password'}
                register={register('password', { required: true })}
                style={
                  errors.password == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
              />
              <p className="mt-2 text-center clear-both text-sm pb-0 m-0 text-[#666]">
                {/* Redirect to send verfication code page */}
                <Link className="text-sm text-black underline" to={'/reset/otp'}>
                  Forgot your password
                </Link>
                &nbsp;
              </p>
              &nbsp; &nbsp;
              <Button
                className="p-0 tracking-normal mb-2 h-10 text-[#fff] text-[17px] w-full shadow-none border border-login-bt-border cursor-pointer bg-black rounded-[3px]"
                buttonText="Log in"
                onclick={(e) => {
                  setVisibility(!visibility);
                }}
                loading={showLoader}
                disabled={showLoader}
              />
              {isPopupEnabled && (
                <CustomPopup onClose={popupCloseHandler} show={visibility} width={'w-41%'}>
                  <div className="block">
                    <p className="mt-0 mr-0 mb-2.5 ml-0 text-[16px]">{message}</p>
                  </div>
                </CustomPopup>
              )}
              {/* </div> */}
            </form>
          </div>
        </div>
        {/* Login Panel End */}
        {/* Background Image start */}
        <div className="h-full w-full mt-0 mr-auto mb-0 min-h-[64.000em] absolute top-0 text-center z-0 block overflow-hidden">
          <picture className="h-full">
            <img
              src={backgroundImage}
              className="h-full relative object-cover bg-center bg-cover bg-repeat w-full"
              alt="Home"
              height="auto"
              width="100%"
            />
          </picture>
        </div>
        {/* Background Image End */}
        {/* Footer Start */}
        <div className="w-full flex-grow-0">
          <Footer />
        </div>
      </div>
      {/* Footer End */}
    </div>
  );
};
export default Login;
