import React from 'react';
import Button from '../../library/Button/button';
import ImageTag from '../../library/ImageTag/ImageTag';
import bin from '../../assets/img/bin.png';
import edit from '../../assets/img/edit.png';

interface Props {
  name: string;
  accessType: string;
  disabled: boolean;
}

const ModuleRow = ({ name, accessType, disabled }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4 text-center">
        <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
          {name}
        </p>
      </td>
      <td className="px-6 py-4 text-center ">
        {' '}
        <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
          {' '}
          {accessType}{' '}
        </p>{' '}
      </td>
      <td className="px-6 py-4 text-center">
        {' '}
        <div className="">
          <Button
            onclick={() => {}}
            disabled={disabled}
            className={disabled ? 'cursor-not-allowed' : ''}
          >
            <ImageTag
              src={edit}
              width={'20px'}
              alt={''}
              style={{ marginLeft: '1rem' }}
              name={'Edit'}
            />
          </Button>
          <Button
            onclick={() => {}}
            disabled={disabled}
            className={disabled ? 'cursor-not-allowed' : ''}
          >
            <ImageTag
              key="Delete"
              src={bin}
              width={'20px'}
              alt={''}
              style={{ marginTop: '-2.4rem', marginLeft: '2rem', margin: 'auto' }}
              name={''}
            />
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default ModuleRow;
