import React, { useEffect, useState } from 'react';
import CloudTrailLog from '../../components/AdminPanel/CloudTrailLog';
import Module from '../../utils/Module/Module';
import ReactLoading from 'react-loading';

const Analytics = () => {
  const [dataModule, setDataModule] = useState<any>([]);
  const [isLoadingModule, setIsLoadingModule] = useState<boolean>(true);

  const getAdminOrganizationModule = async () => {
    setIsLoadingModule(true);
    const organizationAdminObj = new Module();
    const result = await organizationAdminObj.getAllModule();
    setDataModule(result);
    setIsLoadingModule(false);
  };
  useEffect(() => {
    void getAdminOrganizationModule();
  }, []);

  return <CloudTrailLog isLoading={isLoadingModule} moduleInfo={dataModule} />;
};

export default Analytics;
