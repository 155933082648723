import React, { useEffect, useId, useState } from 'react';
import Button from '../../library/Button/button';
import CustomPopup from './CustomPopup';
import ImageTag from '../../library/ImageTag/ImageTag';
import reset from '../../assets/img/reset.png';
import ReseUsertMfa from '../../utils/OrgUser/ReseUsertMfa';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';

interface ResetMfaProps {
  userEmail: string | any;
  disabled: boolean;
}

/* This component will reset users mfa preference when BT admin clicks on reset button. Props are used to send the user email to backend for reseting the mfa preferences. */

export const ResetMfa: React.FC<ResetMfaProps> = ({ userEmail, disabled }) => {
  const uniqueId = useId();
  const [isShowing, setIsShowing] = useState(false);
  // useState to show the popup loader and response message
  const [show, setShow] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const [showAlert, setShowAlert] = useState(false);

  // sending the data From UI to backend via promise
  const removeUserMfa = async () => {
    setShowLoader(true);
    setShow(!show);
    setShowAlert(true);
    setTimeout(() => setShowLoader(false), 1000);

    const resetUserMfa = new ReseUsertMfa(userEmail);
    const deleteStatus = await resetUserMfa.resetMfaUser();
    setShowLoader(showLoader);
    const errorMessage = 'Failed to reset user mfa.';
    const successMessage = 'User mfa reset is successfull.';
    const response = handleApiResponse(deleteStatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setResponseMessage(responseBody);
  };

  // Method to close the popup on clicking the close button
  const popupCloseHandler = (e: boolean) => {
    setShow(e);
  };
  // Method to cancel reset data on clicking the cancel button
  const onCancelHandler = () => {
    setShow(false);
  };
  // Method to show the popup on clicking the reset button
  const onDeleteHandler = async () => {
    setShow(!show);
  };
  // Method to close the popup on clicking the close button
  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(e);
    setShow(e);
  };
  return (
    <>
      <Button
        id={`reset-mfa-${uniqueId}`}
        aria-label="Reset MFA"
        onclick={onDeleteHandler}
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
        disabled={disabled}
        className={disabled ? 'cursor-not-allowed' : ''}
      >
        <ImageTag
          src={reset}
          width={'20px'}
          alt={''}
          style={{ marginTop: '-2.4rem', marginLeft: '2rem', margin: 'auto' }}
          name={'Reset'}
        />
        {isShowing && (
          <div className="group flex relative">
            <span
              className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2
        -translate-x-1/2 translate-y-full m-4 mx-auto"
            >
              Reset user mfa
            </span>
          </div>
        )}
      </Button>
      <CustomPopup onClose={popupCloseHandler} show={show} width={'w-41%'}>
        <h2 className="text-lg font-medium mb-4">Are you sure you want to reset user mfa?</h2>
        <div className="flex justify-center">
          <Button
            className="h-10 px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black mr-3"
            buttonText="Cancel"
            onclick={onCancelHandler}
          />
          <Button
            className="px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3"
            buttonText="Reset"
            onclick={removeUserMfa}
          />
        </div>
      </CustomPopup>
      {showAlert && (
        <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={'w-41%'}>
          <div className="max-h-30%">
            <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
          </div>
        </CustomPopup>
      )}
    </>
  );
};

export default ResetMfa;
