import React, { useState, Fragment, useEffect, useId } from 'react';
import CustomPopup from './CustomPopup';
import ImageTag from '../../library/ImageTag/ImageTag';
import create from '../../assets/img/create.png';
import { useForm } from 'react-hook-form';
import OrgUser from '../../utils/OrgUser/OrgUser';
import IUserList from '../../utils/UserList/UserList.interface';
import Form from '../../library/Form/Form';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import { JobTitle, responseMessages } from '../../utils/Helper/enums';
import Button from '../../library/Button/button';

interface adminUserProps {
  organisationName: string | null;
  organisationEmail: string | null;
  domains?: any;
  onUserAdd: () => void;
}
// Re-usable component for creating Organization users for a specific Organization Admin
export const AdminUserForm: React.FC<adminUserProps> = ({
  organisationName,
  organisationEmail,
  domains,
  onUserAdd
}) => {
  const optionJobTitle = [
    { label: '', value: '' },
    { label: JobTitle.Developer, value: JobTitle.Developer },
    { label: JobTitle.Admin, value: JobTitle.Admin }
  ];

  const [showForm, setShowForm] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [phone, setPhone] = useState<string>('');
  const [isShowing, setIsShowing] = useState(false);
  const uniqueId = useId();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  // Reset fields whenever showForm changes
  useEffect(() => {
    // Reset form fields when opening form
    if (showForm) {
      setName('');
      setEmail('');
      setRole('');
      setPhone('');
      setShowAlert(false);
    }
  }, [showForm]);

  const createUser: any = async (data: IUserList) => {
    setShowLoader(true);
    const userObj = new OrgUser({
      email: data.email ?? '',
      orgEmail: organisationEmail,
      orgName: organisationName,
      name: data.name ?? '',
      role,
      phone
    });

    const createStatus = await userObj.getAllOrgUser();

    reset();

    const errorMessage = responseMessages.UserExists;
    const successMessage = responseMessages.CreateUserSuccess;
    const response = handleApiResponse(createStatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setResponseMessage(responseBody);
    setShowAlert(true);

    if (createStatus.statusCode === 201) {
      onUserAdd();
    }
    setShowLoader(false);
  };

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const popupCloseHandler = () => {
    setShowForm(false);
    reset();
  };

  return (
    <Fragment>
      <Button
        id={`create-user-${uniqueId}`}
        aria-label="Create User"
        onclick={handleButtonClick}
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
      >
        <ImageTag src={create} width={'20px'} alt={''} name={'Create'} />
        {isShowing && (
          <div className="group flex relative">
            <span
              className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2
         -translate-x-1/2 translate-y-full m-4 mx-auto"
            >
              Add User
            </span>
          </div>
        )}
      </Button>
      {/* ToDo: Design of CustomPopUp is not good, will refactor it later. */}
      <CustomPopup onClose={popupCloseHandler} show={showForm} width={'w-5/6 md:w-3/4'}>
        <Form
          orgEmail={email}
          register={register}
          role={role}
          setRole={setRole}
          organisationName={organisationName}
          organisationEmail={organisationEmail}
          optionJobTitle={optionJobTitle}
          createUser={handleSubmit(createUser)}
          show={showAlert}
          setShowForm={setShowForm}
          message={responseMessage}
          showLoader={showLoader}
          errors={errors}
          setPhone={setPhone}
          phone={phone}
          setName={setName}
          setEmail={setEmail}
          isBtAdmin={false}
          usersEmail={''}
          onSubmit={function (e: React.FormEvent<Element>): void {
            throw new Error('Function not implemented.');
          }}
          reset={reset}
          optionDomain={domains}
        />
      </CustomPopup>
    </Fragment>
  );
};
