import React from 'react';
import WelcomeHeader from '../components/TopBar/WelcomeHeader';
import login from '../assets/img/login.png';
import Footer from '../components/Footer';
import Header from '../components/Header';

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  return (
    <div className="h-screen min-h-fit xl:max-h-screen relative overflow-hidden flex flex-col justify-center items-center p-0">
      {/* Header start */}
      <div className="w-full flex-grow-0">
        <Header>
          <WelcomeHeader />
        </Header>
      </div>
      {/* Header End */}
      {/* Border Start */}
      <div
        className="w-full p-0 z-50 mt-0 clear-both h-px"
        style={{
          borderTop: '1px solid #ccc'
        }}
      ></div>
      {/* Border End */}
      {/* Login Panel start */}
      <div className="flex-grow w-full relative p-0 flex flex-col justify-center items-center">
        <div className="relative flex-grow m-0 p-2 sm:p-10 z-[5] flex justify-center items-center w-full">
          {children}
        </div>
        {/* Login Panel End */}
        {/* Background Image start */}
        <div className="h-full w-full mt-0 mr-auto mb-0 min-h-[64.000em] absolute top-0 text-center z-0 block overflow-hidden">
          <picture className="h-full">
            <img
              src={login}
              className="h-full relative object-cover bg-center bg-cover bg-repeat w-full z-0"
              alt="Home"
              height="auto"
              width="100%"
            />
          </picture>
        </div>
        {/* Background Image End */}
        {/* Footer Start */}
        <div className="w-full relative flex-grow-0">
          <Footer />
        </div>
      </div>
      {/* Footer End */}
    </div>
  );
};

export default AuthLayout;
