import React, { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import SendVerificationCode from '../utils/PasswordRsest/sendVerificationCode';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import ResetPasswordForm from './ResetPasswordForm';
import Button from '../library/Button/button';
import UserLogin from '../utils/UserLogin/UserLogin';
import { wrapAsyncFunction } from '../components/UtilityFunction/wrapAsyncFunction';
import InputField from '../library/inputField/inputField';
import AuthLayout from '../layout/AuthLayout';
// This component is responsible to send verfication code to user email if user forgot their password and want to reset their password.
const ForgotPassword: React.FC<any> = (props: any) => {
  const navigate: NavigateFunction = useNavigate();
  const [codeStatus, setCodeStatus] = useState(false);
  const [email, setEmail] = useState('');
  const [show, setShow] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userPass, setUserPass] = useState('');
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  // Method to check the entered user email is valid or not and if valid then store it in a state.
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(enteredEmail)) {
      setEmailError('Invalid email format');
      setIsFormValid(false);
    } else {
      setEmailError('');
      setIsFormValid(true);
    }
  };
  // storing user email to session storage for reseting password
  sessionStorage.setItem('userEmail', email);

  // Method to send verification code to user email.
  const handleForgotPassword = async () => {
    setShowAlert(true);
    setShow(!show);
    const sendVerificationCodeObj = new SendVerificationCode({
      email
    });
    const sendCodeStatus = await sendVerificationCodeObj.sendCode();
    setEmail(email);
    switch (sendCodeStatus.statusCode) {
      case 201:
        setResponseMessage('sent code successfully');
        break;
      case 400:
        setResponseMessage(sendCodeStatus.body);
        setIsResend(true);
        break;
      case 404:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 429:
        setResponseMessage(sendCodeStatus.body);
        break;
      default:
        setResponseMessage('Failed to send code');
        break;
    }
    if (sendCodeStatus.statusCode === 201) {
      setCodeStatus(true);
    }
  };

  // Resend mail for user who wants to sign-in to Organization User page
  const resendMail = async () => {
    setShowLoader(true);
    setShowAlert(true);
    setShow(!show);
    const userLoginObj = new UserLogin({ email: email || '', password: userPass || '' });
    const res = await userLoginObj.resendUrl();
    setShowLoader(false);
    switch (res.statusCode) {
      case 201:
        setResponseMessage('Verification Link has been sent to your email');
        setEmail('');
        setUserPass('');
        setIsResend(false);
        break;
      default:
        setResponseMessage('Failed to send verification link');
        setEmail('');
        setUserPass('');
        setIsResend(false);
        break;
    }
  };
  // method to close popup.
  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(e);
    setShow(e);
    if (!codeStatus) {
      navigate(0);
    }
  };
  // method to handle enter key.
  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isFormValid) {
      event.preventDefault();
      await handleForgotPassword();
    }
  };

  return (
    <AuthLayout>
      <div className="min-w-[288px] w-72 sm:w-[20rem] max-w-[28rem] block pointer-events-auto sm:p-[0.5rem] rounded-lg shadow-lg bg-white">
        {!codeStatus ? (
          <div className="p-4">
            <div className="w-full">
              <h2 className="text-[22px] text-center mb-[15px]">Send Verification Code</h2>
              <div className="mt-2">
                {emailError && <p className="text-red-500 text-xs">{emailError}</p>}
              </div>

              <InputField
                id={'VerifiedEmail'}
                className={`h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] ${
                  emailError ? 'border-red' : ''
                }`}
                type="email"
                placeholder="Enter your verified email"
                value={email}
                onChange={handleEmailChange}
                onKeyUp={handleKeyUp}
              />
            </div>
            <Button
              className="mt-2 content-center p-0 tracking-normal mb-4 h-10 text-[#fff] text-[17px] w-full shadow-none border border-login-bt-border cursor-pointer bg-black rounded-[3px]"
              onclick={wrapAsyncFunction(handleForgotPassword)}
              disabled={!isFormValid}
              buttonText="Send Code"
            />
            <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={'w-41%'}>
              <div className="max-h-30%">
                <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
                {isResend && (
                  <Button
                    className="my-2 mx-4 h-[33px] text-[#fff] text-[16px] w-60 cursor-pointer bg-black rounded-[10px]"
                    buttonText="Resend verification email"
                    onclick={wrapAsyncFunction(resendMail)}
                    loading={showLoader}
                    disabled={showLoader}
                  />
                )}
              </div>
            </CustomPopup>
          </div>
        ) : (
          <div>
            <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={'w-41%'}>
              <div className="max-h-30%">
                <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
              </div>
            </CustomPopup>
            <ResetPasswordForm />
          </div>
        )}
      </div>
    </AuthLayout>
  );
};
export default ForgotPassword;
