import React, { useEffect, useState } from 'react';
import Preset from '../../utils/ModulePresets/Preset';
import Module from '../../utils/Module/Module';
import OrganizationAdmin from '../../utils/OrganizationAdmin/OrganizationAdmin';
import IModuleList from '../../utils/ModuleList/ModuleList.interface';
import { IPreset } from '../../utils/ModulePresets/preset.interface';
import IUserList from '../../utils/UserList/UserList.interface';
import StatCard from '../../components/StatCard';

const BtAdminPanel: React.FC<any> = () => {
  const [partnerList, setPartnerList]: any = useState<Array<IUserList[] | null[]>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [presets, setPresets] = useState<IPreset[] | []>([]);
  const [systemModuleName, setSystemModuleName] = useState<IModuleList[]>([]);
  const [baseModules, setBaseModules] = useState<IModuleList[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // Get Partner Organizations
      const organizationAdminObj = new OrganizationAdmin();
      const partnerList = await organizationAdminObj.getAllOrganization();
      setPartnerList(partnerList);

      // Get Presets
      const presetInst = new Preset();
      const presetList = await presetInst.getAllPreset();
      setPresets(presetList ?? []);

      // Get Module list
      const module = new Module();
      const moduleList = await module.getAllModule();
      // Filter system modules based on the isSystemModule property
      const systemModules = moduleList.filter((module) => module?.isSystemModule) as IModuleList[];
      // Filter modules where isSystemModule is false
      const nonSystemModules = moduleList.filter(
        (module) => !module?.isSystemModule
      ) as IModuleList[];
      setSystemModuleName(systemModules);
      setBaseModules(nonSystemModules);
      setIsLoading(false);
    };

    void fetchData();
  }, []);

  return (
    <div className="max-w-full overflow-hidden overflow-x-scroll">
      <div className="max-w-full h-fit py-4 px-4 flex flex-wrap flex-col md:flex-row justify-center md:justify-start gap-4">
        <StatCard
          className="w-full md:w-40 h-32 md:h-40 lg:h-56 lg:w-56 aspect-square"
          title="Partners"
          value={partnerList.length}
          isLoading={isLoading}
        />
        <StatCard
          className="w-full md:w-40 h-32 md:h-40 lg:h-56 lg:w-56 aspect-square"
          title="Total Modules"
          value={baseModules.length + systemModuleName.length}
          isLoading={isLoading}
        />
        <StatCard
          className="w-full md:w-40 h-32 md:h-40 lg:h-56 lg:w-56 aspect-square"
          title="Base Modules"
          value={baseModules.length}
          isLoading={isLoading}
        />
        <StatCard
          className="w-full md:w-40 h-32 md:h-40 lg:h-56 lg:w-56 aspect-square"
          title="System Modules"
          value={systemModuleName.length}
          isLoading={isLoading}
        />
        <StatCard
          className="w-full md:w-40 h-32 md:h-40 lg:h-56 lg:w-56 aspect-square"
          title="Persets"
          value={presets.length}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
export default BtAdminPanel;
