import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WelcomeHeader from '../components/TopBar/WelcomeHeader';
import login from '../assets/img/login.png';
import MfaSetupQrCode from '../components/MfaSetupQrCode';
import OtpValidate from '../components/OtpValidate';
import UserLogin from '../utils/UserLogin/UserLogin';
import { UserType } from '../utils/Helper/enums';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { LoginContext } from '../context/IppContext';
import { decrypt } from '../utils/Helper/encryption';
import { adminRoutes, btAdminRoutes, routes, userRoutes } from '../routes/Routes';
import AuthLayout from '../layout/AuthLayout';

const Mfa: React.FC<any> = (props: any) => {
  const [secretCode, setSecretCode] = useState(sessionStorage.getItem('secretCode'));
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken') ?? '');
  const [isMfaSetupDone, setIsMfaSetupDone] = useState<boolean>(
    sessionStorage.getItem('isMfaSetup') === 'true'
  );
  const [email, setEmail] = useState(sessionStorage.getItem('userEmail'));
  const [userPass, setUserPass] = useState(sessionStorage.getItem('userPassword') ?? '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { loginToken, updateToken } = useContext(LoginContext);
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem('userEmail')) {
      navigate('/login');
    }
  }, []);

  return (
    <AuthLayout>
      <div className="min-w-[288px] w-72 sm:w-[20rem] max-w-[28rem] block rounded-lg shadow-lg bg-white p-4">
        {isMfaSetupDone ? (
          <OtpValidate
            isLoading={isLoading}
            onOtpSubmission={async (code: string) => {
              setIsLoading(true);
              const userLoginObj = new UserLogin({
                email: email ?? '',
                password: userPass ?? ''
              });
              const loginStatus = await userLoginObj.doLogin(code);
              const responseData: any = loginStatus?.body ?? {};
              const userType = responseData?.userType ?? Number.MAX_SAFE_INTEGER;
              switch (loginStatus.statusCode) {
                case 201:
                  switch (userType) {
                    case UserType.BtAdmin:
                      setIsLoading(false);
                      navigate(btAdminRoutes.partnerAdmin);
                      break;
                    case UserType.OrganizationAdmin:
                      setIsLoading(false);
                      updateToken(JSON.stringify(responseData));
                      navigate(adminRoutes.dashoard);
                      break;
                    case UserType.OrganizationUser:
                      setIsLoading(false);
                      updateToken(JSON.stringify(responseData));
                      navigate(userRoutes.dashboard);
                      break;
                    default:
                      break;
                  }
                  break;
                case 410:
                  setIsLoading(false);
                  alert('Sorry OTP mismatched');
                  break;
                case 401:
                  setIsLoading(false);
                  alert('Invalid credentials.');
                  navigate(routes.login);
                  break;
                case 403:
                  setIsLoading(false);
                  alert('Sorry something went wrong, please try again later.');
                  break;
                case 500:
                  setIsLoading(false);
                  alert('Sorry something went wrong, please try again later.');
                  break;
                default:
              }
              // Storing the login token and email address of the user
              sessionStorage.setItem('user-type', userType);
            }}
          />
        ) : (
          <MfaSetupQrCode
            isLoading={isLoading}
            secretCode={secretCode ?? ''}
            onOtpVerification={async (code: string) => {
              setIsLoading(true);
              const userLoginObj = new UserLogin({
                email: email ?? '',
                password: userPass ?? ''
              });
              const loginStatus = await userLoginObj.userMfaSetup(code, accessToken);
              const responseData: any = loginStatus?.body ?? {};
              if (loginStatus.statusCode === 201) {
                setIsLoading(false);
                setIsMfaSetupDone(true);
                alert('Your MFA setup is successfull.');
                navigate(routes.login);
              } else {
                setIsLoading(false);
                alert('Sorry something went wrong, please try again later.');
              }
            }}
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default Mfa;
