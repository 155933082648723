import React, { useEffect, useState } from 'react';
import PanelLayout from './PanelLayout';
import AdminTopBar from '../components/TopBar/AdminTopBar';
import { Outlet, useNavigate } from 'react-router-dom';
import { IPPFourthLevelHeading, IPPParagraph } from '../library/Heading/Heading';
import LeftNavPanel from '../components/LeftNavPanel';
import { UserDetailProvider } from '../context/UserDetailContext';
import { InitialFlag } from '../utils/Helper/enums';
import { useUserAndAdminInitials } from '../utils/GetInitials/GetInitials';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import { FeedbackForm } from '../components/Feedback/Feedback';
import withLogout from '../components/AutoLogOut/withLogout';
import useTimeOfDay from '../hooks/useTimeOfDay';
import UserGreeting from '../components/UserGreeting';
import { adminRoutes, userRoutes } from '../routes/Routes';

interface Props {
  isAdmin: boolean;
}

const PartnerPanelLayout = ({ isAdmin }: Props) => {
  const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false);
  const userGroup: string | null = sessionStorage.getItem('groupName');
  const userEmail = sessionStorage.getItem('userEmail') as string;
  const { calculatedInitials, getFullName } = useUserAndAdminInitials(
    userEmail,
    isAdmin ? InitialFlag.Admin : InitialFlag.User
  );
  const navigate = useNavigate();
  const tabs = [
    {
      label: 'Home',
      path: isAdmin ? adminRoutes.dashoard : userRoutes.dashboard
    }
  ];
  const links = [
    {
      label: 'Manage Licenses/Agreements',
      path: ''
    },
    {
      label: 'Documentation',
      path: ''
    },
    {
      label: 'Feedback / Query',
      path: '',
      onClick: () => {
        setShowFeedbackForm(true);
      }
    }
  ];

  // Method to close the create user form on clicking the close button
  const popupCloseHandler = (e: boolean) => {
    setShowFeedbackForm(e);
    navigate(0);
  };

  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      sessionStorage.clear();
      navigate('/login');
    });
  }, []);

  return (
    <UserDetailProvider
      value={{
        fullName: getFullName,
        firstName: getFullName,
        calculatedInitials: calculatedInitials
      }}
    >
      <PanelLayout header={<AdminTopBar isAdmin={isAdmin} isUser={!isAdmin} />} footer={<></>}>
        <LeftNavPanel tabs={tabs} links={links} />
        <div className="flex-grow min-w-[400px] overflow-hidden overflow-x-auto transition-all ease-in-out overflow-y-auto px-0 pl-2 sm:px-4 lg:px-8 pb-20">
          <UserGreeting name={getFullName} group={userGroup ?? ''} isAdmin={isAdmin} />
          <hr />
          <div className="w-full h-fit pb-12">
            <Outlet />
            <CustomPopup onClose={popupCloseHandler} show={showFeedbackForm} width={'w-[800px]'}>
              <FeedbackForm
                orgEmail={userEmail}
                name={getFullName}
                onClose={() => {
                  setShowFeedbackForm(false);
                }}
              />
            </CustomPopup>
          </div>
        </div>
      </PanelLayout>
    </UserDetailProvider>
  );
};

export default withLogout(PartnerPanelLayout);
