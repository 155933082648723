// These hooks are used to storing variables and will change according to user interaction
import React, { ReactNode, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
const CustomPopup = (props: {
  children?: ReactNode | undefined;
  onClose: (arg0: boolean) => void;
  show: any;
  width: string;
}) => {
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently is false.
  const [show, setShow] = useState(false);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };

  // The useEffect Hook allows us to perform side effects in our components.
  useEffect(() => setShow(props.show), [props.show]);
  return (
    <div
      style={{
        visibility: show ? 'visible' : 'hidden',
        opacity: show ? '1' : '0'
      }}
      className="opacity-25 bg-trans justify-center items-center flex overflow-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div
        className={`p-4 pt-10 sm:p-[35px] bg-white ${props.width} max-w-full sm:max-w-md md:max-w-lg lg:max-w-4xl xl:max-w-6xl rounded-md relative transition-all duration-5000`}
      >
        <span
          className="absolute top-[0px] right-[10px] transition-all text-3xl font-bold text-black hover:cursor-pointer"
          onClick={closeHandler}
        >
          <div className="close mt-[17px]"></div>
        </span>
        <div className="max-h-30% ">{props.children}</div>
      </div>
    </div>
  );
};

CustomPopup.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired
};
export default CustomPopup;
