import React, { Fragment, useState } from 'react';
import ReactLoading from 'react-loading';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../library/Button/button';
import IModuleList from '../../utils/ModuleList/ModuleList.interface';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import ConditionalLoader from '../Hoc/ConditionalLoader';
/**
 * This common props takes in several parameters.
 * @moduleInfo - These prop is likely used to store the modules list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 **/
interface ICloudTrailLog {
  key?: any;
  moduleInfo: any;
  isLoading: boolean;
}

// ModuleList is a functional component which displays a table of repositories that organisation has access to.
const CloudTrailLog: React.FC<ICloudTrailLog> = ({ moduleInfo, isLoading }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isNotSelectedstartDate, setNotSelectedStartDate] = useState(true);
  const [isNotSelectedendDate, setisNotSelectedEndDate] = useState(true);

  const [isModuleSelectEnable, setModuleSelectEnable] = useState<boolean>(true);
  const [isGitOperationSelectEnable, setGitOperationSelectEnable] = useState<boolean>(true);
  const [gitOperationSelect, setGetOperationSelect] = useState(null);
  const [selectModuleName, setselectModuleName] = useState(null);
  const [logInfo, setLogInfo] = useState<any>();
  const [isLoadingModule, setLoadingModule] = useState<boolean>(false);

  const onChangeModuleSelect = (event: any): any => {
    if (event.target.value !== undefined && event.target.value !== 'select') {
      setselectModuleName(event.target.value);
    } else {
      setselectModuleName(null);
    }
  };
  const onChangeGitOperationSelect = (event: any): any => {
    if (event.target.value !== undefined && event.target.value !== 'select') {
      setGetOperationSelect(event.target.value);
    } else {
      setGetOperationSelect(null);
    }
  };
  const fetchData = async () => {
    const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
    const jwtToken = sessionStorage.getItem('jwtToken');
    const preferredRole = sessionStorage.getItem('preferredRole');
    const apiKey = String(process.env.REACT_APP_API_KEY ?? '');
    const config = {
      headers: {
        Authorization: jwtToken,
        'x-api-key': apiKey,
        'Role-Name': preferredRole
      }
    };
    const url = `${backEndServerUrl}/getCloudTrail`;
    if (
      gitOperationSelect === undefined ||
      gitOperationSelect === 'select' ||
      gitOperationSelect === null
    ) {
      setGitOperationSelectEnable(false);
    } else {
      const requestBody: any = {
        attributeValue: gitOperationSelect
      };
      if (!_.isEmpty(selectModuleName)) {
        requestBody.repoName = selectModuleName;
      }
      if (!_.isEmpty(String(startDate ?? ''))) {
        requestBody.startDate = moment(startDate).format('yyyy-MM-DD');
      }
      if (!_.isEmpty(String(endDate ?? ''))) {
        requestBody.endDate = moment(endDate).format('yyyy-MM-DD');
      }
      if (_.isEmpty(String(startDate ?? '')) && _.isEmpty(String(endDate ?? ''))) {
        setNotSelectedStartDate(false);
      } else if (_.isEmpty(String(startDate ?? '')) && !_.isEmpty(String(endDate ?? ''))) {
        setNotSelectedStartDate(false);
      } else if (!_.isEmpty(String(startDate ?? '')) && _.isEmpty(String(endDate ?? ''))) {
        setisNotSelectedEndDate(false);
      } else {
        setLoadingModule(true);
        const response = await axios.post(url, requestBody, config);
        if (response.status === 201) {
          setLoadingModule(false);
          setLogInfo(response.data);
        } else if (response.status === 504) {
          setLogInfo(null);
          alert('We apologise; a high volume of cloud trail logs is causing the API to time out..');
          setLogInfo([]);
        } else {
          setLoadingModule(false);
          alert('Please try after some time..');
        }
      }
    }
  };
  const fetchTrailData = async () => {
    try {
      await fetchData();
    } catch (err: any) {
      setLoadingModule(false);
      setLogInfo(null);

      alert('We apologise; a high volume of cloud trail logs is causing the API to time out..');
    }
  };
  return (
    <Fragment>
      <div className="w-full overflow-hidden">
        <br />
        <br />
        {/* Todo - fix with next release */}
        {/* <Show onChange={handleNumValuesChange} /> */}
        <ConditionalLoader isLoading={isLoading}>
          <div>
            <div className="pb-4 overflow-hidden overflow-x-scroll">
              <div className="w-fit mx-auto">
                <table className=" max-w-4xl  table-fixed whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                  <thead className="bg-[#f8f8f8]">
                    <tr className="text-black text-center">
                      <th className="font-semibold text-sm uppercase px-14 py-4">
                        {' '}
                        Git Operations{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-14 py-4"> Repo Name</th>
                      <th className="font-semibold text-sm uppercase px-14 py-4"> Start Date </th>
                      <th className="font-semibold text-sm uppercase px-14 py-4"> End Date </th>
                      <th className="font-semibold text-sm uppercase px-14 py-4"> Action </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td>
                        <div className="px-0 ml-[3rem]">
                          <div
                            id="operation_selectError"
                            className=" text-white cursor-pointer text-[1em] z-[1] rounded-[5px] w-[66%]"
                            style={
                              !isGitOperationSelectEnable
                                ? { border: '2px solid rgb(230, 0, 20)' }
                                : {}
                            }
                          >
                            <select
                              id="operation_select"
                              value={gitOperationSelect ?? ''}
                              className=" bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/br_down.png')] bg-[32px] h-10 py-[0.1em] rounded-[5px] pl-[5px] text-[#555] pr-[33px] m-0 bg-no-repeat overflow-hidden appearance-none"
                              style={{
                                backgroundPosition: 'right',
                                border: '1px solid #666',
                                backgroundSize: '32px'
                              }}
                              onChange={onChangeGitOperationSelect}
                              onClick={() => {
                                setGitOperationSelectEnable(true);
                              }}
                              required={true}
                            >
                              <option value="select" label="--Select--" />
                              <option value="GitPull" label="GitPull" />
                              <option value="GitPush" label="GitPush" />
                            </select>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="px-0 ml-[3rem]">
                          <div
                            id="operation_selectError"
                            className=" text-white cursor-pointer text-[1em] z-[1] rounded-[5px] "
                            style={
                              !isModuleSelectEnable ? { border: '2px solid rgb(230, 0, 20)' } : {}
                            }
                          >
                            <select
                              id="module_select"
                              value={selectModuleName ?? ''}
                              className="w-[80%] bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/br_down.png')] bg-[32px] h-10 py-[0.1em] rounded-[5px] pl-[5px] text-[#555] pr-[33px] m-0 bg-no-repeat overflow-hidden appearance-none"
                              style={{
                                backgroundPosition: 'right',
                                border: '1px solid #666',
                                backgroundSize: '32px'
                              }}
                              onChange={onChangeModuleSelect}
                              onClick={() => {
                                setModuleSelectEnable(true);
                              }}
                              required={true}
                            >
                              <option value="select" label="--Select--" />
                              {moduleInfo.map((element: IModuleList, index: number) => {
                                return (
                                  <option
                                    value={element.moduleName ?? ''}
                                    label={element.moduleName ?? ''}
                                    key={index}
                                  />
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="px-0 ml-[6rem]">
                          <div
                            id="start_date_div"
                            className="cursor-pointer text-[1em] z-[1]"
                            style={
                              !isNotSelectedstartDate ? { border: '2px solid rgb(230, 0, 20)' } : {}
                            }
                          >
                            <DatePicker
                              selected={startDate}
                              onChange={(date: any) => {
                                setStartDate(date);
                                setNotSelectedStartDate(true);
                              }}
                              placeholderText="Select Date"
                              dateFormat="yyyy-MM-dd"
                              maxDate={new Date()}
                              required={true}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="px-0 ml-[6rem]">
                          <div
                            id="end_date_div"
                            className=" cursor-pointer text-[1em] z-[1]"
                            style={
                              !isNotSelectedendDate ? { border: '2px solid rgb(230, 0, 20)' } : {}
                            }
                          >
                            <DatePicker
                              selected={endDate}
                              onChange={(date: any) => {
                                moment(date).format('yyyy-MM-dd');
                                setEndDate(date);
                                setisNotSelectedEndDate(true);
                              }}
                              placeholderText="Select Date"
                              dateFormat="yyyy-MM-dd"
                              maxDate={new Date()}
                              minDate={new Date(startDate ?? new Date())}
                              required={true}
                            />
                          </div>
                        </div>
                      </td>

                      <td>
                        {' '}
                        <Button
                          className="inline-block mb-0 h-[42px] ml-[3.25rem]"
                          buttonText="Submit"
                          onclick={fetchTrailData}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <ConditionalLoader isLoading={isLoadingModule}>
              <div>
                <div className="w-full my-14">
                  <p className="text-center font-bold">
                    Totall RepoCount: - {logInfo?.totallRepoCount ?? 0}
                  </p>
                </div>
                {logInfo?.repoDetail.length === 0 ? (
                  <div className="mx-auto max-w-4xl text-center">
                    <p>
                      <b>No Log Data Found</b>
                    </p>
                  </div>
                ) : (
                  <div className="w-full overflow-hidden overflow-x-auto">
                    <div className="w-fit mx-auto">
                      {logInfo?.repoDetail.map((info: any, index: number) => {
                        return (
                          <Fragment key={index}>
                            <div key={info[0]}>
                              <div className="text-center ">
                                <span key={`${info[0]}${index}`}>
                                  <b>Repo Name:</b> {info[0]} ||
                                </span>
                                &nbsp;
                                <span key={`${info[1].totalCount}${index}`}>
                                  <b>Repo Count:</b> {info[1].totalCount}
                                </span>
                              </div>
                              <div className="">
                                <table
                                  key={info[0]}
                                  className="whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300 mb-3.5 ml-1"
                                >
                                  <thead className="bg-[#f3f3f3] ">
                                    <tr className="text-black text-left border-l">
                                      <th className="py-1 pl-4 border-y">OrgName</th>
                                      <th className="py-1 pl-4 border-y">Email</th>
                                      <th className="py-1 pl-4 border-y border-r">EventTime</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {info[1].Details.map((logInfo: any, index: Number) => {
                                      return (
                                        <tr
                                          key={`${logInfo.orgName}${index}`}
                                          className="odd:bg-white bg-[#f8f8f8] text-black text-left"
                                        >
                                          <td className="px-4 border-b border-l">
                                            {logInfo.orgName}
                                          </td>
                                          <td className="px-4 border-b">{logInfo.email}</td>
                                          <td className="px-4 border-b border-r">
                                            {new Date(logInfo.eventTime).toString()}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                                <br />
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </ConditionalLoader>
          </div>
        </ConditionalLoader>
      </div>
    </Fragment>
  );
};

export default CloudTrailLog;
