import React, { useEffect, useState } from 'react';
import Button from '../../library/Button/button';
import PartnerDomainRow from './PartnerDomainRow';
import IDomain from '../../utils/Domain/Domain.interface';

interface PartnerDomainListProps {
  domains: string[];
  emailList: string[];
  onDomainDelete: (domain: string[]) => void;
  isLoading: boolean;
}

const PartnerDomainList: React.FC<PartnerDomainListProps> = ({
  domains,
  emailList,
  onDomainDelete,
  isLoading
}) => {
  const toDomainList = (domains: string[]): IDomain[] => {
    const domainList: Array<IDomain> = [];
    domains.forEach((domain) =>
      domainList.push({
        domainNamne: domain,
        isSelected: false
      })
    );
    return domainList;
  };
  const fromDomainList = (domains: IDomain[]): string[] => {
    const domainList: string[] = domains
      .filter((domain) => !domain.isSelected)
      .map((domain) => domain.domainNamne);
    return domainList;
  };
  const [domainCheckboxList, setDomainCheckboxList] = useState<IDomain[]>(toDomainList(domains));
  const [isHover, setIshover] = useState<boolean>(false);

  useEffect(() => {
    const updateDomain = toDomainList(domains);
    setDomainCheckboxList(updateDomain);
  }, [domains.length]);
  return (
    <div className="block w-full">
      {domainCheckboxList.map((domain: IDomain, index: number) => (
        <PartnerDomainRow
          domain={domain.domainNamne}
          key={index}
          isSelected={domain.isSelected}
          onDomainChecked={(isSelected: boolean) => {
            if (!emailList.some((email) => email.includes(domain.domainNamne))) {
              const newDomainCheckboxList = [...domainCheckboxList];
              newDomainCheckboxList[index].isSelected = isSelected;
              setDomainCheckboxList(newDomainCheckboxList);
            } else {
              alert('Sorry there is existing user under this domain.');
            }
          }}
        />
      ))}
      <Button
        className="px-4 py-[1.75] h-7 w-24 bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3"
        loading={isLoading}
        onMouseEnter={() => {
          setIshover(true);
        }}
        onMouseLeave={() => {
          setIshover(false);
        }}
        onclick={() => {
          const selectedDomainsToBeDeleted = fromDomainList(domainCheckboxList);
          if (selectedDomainsToBeDeleted.length > 0) {
            onDomainDelete(selectedDomainsToBeDeleted);
          } else {
            alert('Sorry there is no domain selected for deletion.');
          }
        }}
        buttonText="Delete"
        loadingColour={isHover ? 'white' : 'black'}
      ></Button>
    </div>
  );
};

export default PartnerDomainList;
