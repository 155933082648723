import React from 'react';
import { EditUserForm } from './EditUser';
import { DeleteUser } from './DeleteUser';
import IUserList from '../../utils/UserList/UserList.interface';

interface Props {
  user: IUserList;
  disabled: boolean;
  onEditUser: (user: IUserList) => void;
  onDeleteUser: (email: string) => void;
}

const UserRow = ({ user, disabled, onEditUser, onDeleteUser }: Props) => {
  return (
    <tr>
      <td className="px-6 py-4 text-center">
        <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
          {user?.name}
        </p>
      </td>
      <td className="px-6 py-4 text-center ">
        {' '}
        <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
          {' '}
          {user?.email}{' '}
        </p>{' '}
      </td>
      {user?.role === null ? (
        <></>
      ) : (
        <td className="px-6 py-4 text-center ">
          {' '}
          <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
            {' '}
            {user?.role}{' '}
          </p>{' '}
        </td>
      )}
      <td className="px-6 py-4 text-center">
        {' '}
        <div className="">
          <EditUserForm
            orgPartnerCompanyEmail={user.orgEmail}
            userName={user.name}
            orgJobTitle={user.role}
            orgPartnerCompanyName={user.companyName}
            userEmail={user.email}
            disabled={disabled}
            userPhone={user.phone}
            onEditUser={onEditUser}
          />
          <DeleteUser
            email={user.email!}
            orgEmail={user.orgEmail!}
            disabled={disabled}
            onDeleteUser={onDeleteUser}
          />
        </div>
      </td>
    </tr>
  );
};

export default UserRow;
