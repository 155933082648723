import React from 'react';
import QRCode from 'react-qr-code';
import OtpValidate from './OtpValidate';

// this a reusable component for setting up the autheticator in the google authenticator and validate it.
// It takes the secrect code and a onOtpVerification callback function.
interface MfaSetupQrCodeProps {
  secretCode: string;
  onOtpVerification: Function;
  isLoading: boolean;
}

const MfaSetupQrCode: React.FC<MfaSetupQrCodeProps> = ({
  secretCode,
  onOtpVerification,
  isLoading
}) => {
  return (
    <div className="flex flex-col justify-center items-start">
      <div className="text-[22px] text-start mb-[15px]">Setup authenticator</div>
      <p className="mb-1 text-[12px] mt-1 block">
        Please scan this QR code with Google Authenticator.
      </p>
      <p className="mb-1 text-[12px] block">
        Once you complete the setup, please press done button.
      </p>
      <div style={{ height: 'auto', margin: '0 auto', maxWidth: 80, width: '100%' }}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={'otpauth://totp/IndigoPartnerPortal?secret=' + secretCode}
          viewBox={'0 0 256 256'}
        />
      </div>
      <div className="flex flex-1 flex-row justify-center w-full">
        <OtpValidate
          isLoading={isLoading}
          onOtpSubmission={(code: string) => {
            onOtpVerification(code);
          }}
        />
      </div>
    </div>
  );
};

export default MfaSetupQrCode;
