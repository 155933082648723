import React, { useState, Fragment, useEffect } from 'react';
import CustomPopup from '../BtAdminPanel/CustomPopup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Form from '../../library/Form/Form';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import { responseMessages } from '../../utils/Helper/enums';
import SubmitFeedback from '../../utils/Feedback/Feedback';
import { wrapAsyncFunction } from '../UtilityFunction/wrapAsyncFunction';
import { adminRoutes, btAdminRoutes, userRoutes } from '../../routes/Routes';

interface editAdminUserProps {
  orgEmail: string | any;
  name: string | any;
  onClose?: Function;
}
// Re-usable component for updating Organization users for a specific Organization Admin
export const FeedbackForm: React.FC<editAdminUserProps> = ({ orgEmail, name, onClose }) => {
  // Interface for declaring data types
  interface formDetails {
    feedbackMessage?: string | null;
  }

  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently the db value.
  const [email, setEmail] = useState(orgEmail);
  const [userName, setUserName] = useState<string | any>(name);
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');
  // Declaring state for handling errors.
  const [errors, setErrors] = useState<formDetails>({});
  const [showLoader, setShowLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);

  // Default typescript library to navigate to a certain path after user is created successfully
  const navigate = useNavigate();

  // Form validation code added to useState
  useEffect(() => {
    validateForm();
  }, [feedbackMessage]);

  useEffect(() => {
    setUserName(name);
  }, [name]);

  // Form validation code added here
  const validateForm = () => {
    const errors: formDetails = {};
    if (!feedbackMessage.trim()) {
      errors.feedbackMessage = 'Please provide your valuable feedback / query';
    }
    setErrors(errors);
  };

  const location = useLocation();
  const isAdmin = location.pathname === adminRoutes.dashoard;
  const btAdmin = location.pathname.includes(btAdminRoutes.dashboard, 0);
  const isUser = location.pathname === userRoutes.dashboard;

  // Storing data from UI to db via promise
  const onSubmit: any = async (event: React.FormEvent) => {
    event.preventDefault();
    setShowLoader(true);
    // To show the loader after submitting the form
    setTimeout(() => setShowLoader(false), 3000);
    validateForm();
    // Form submission code here
    const userObj = new SubmitFeedback({
      email,
      name: userName,
      feedback: feedbackMessage
    });

    // API responses have been merged since actions are same
    const feedbackSubmissionStatus = await userObj.submitFeedback();
    setEmail(email);
    setUserName(userName);
    setFeedbackMessage('');

    const errorMessage = responseMessages.SomethingWentWrong;
    const successMessage = responseMessages.FeedbackSubmitted;
    const response = handleApiResponse(
      feedbackSubmissionStatus.statusCode,
      errorMessage,
      successMessage
    );
    const responseBody = response.body;
    setResponseMessage(responseBody);
    setShowAlert(true);

    if (btAdmin && feedbackSubmissionStatus.statusCode === 201) {
      navigate(btAdminRoutes.dashboard);
    } else if (isAdmin && feedbackSubmissionStatus.statusCode === 201) {
      navigate(adminRoutes.dashoard);
    } else if (isUser && feedbackSubmissionStatus.statusCode === 201) {
      navigate(userRoutes.dashboard);
    }
  };

  const closeAllPopUp = () => {
    setShowAlert(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Form
      orgEmail={email}
      show={showAlert}
      showLoader={showLoader}
      errors={errors}
      onSubmit={wrapAsyncFunction(onSubmit)}
      setShowForm={closeAllPopUp}
      createUser={function (e: React.FormEvent<Element>): void {
        throw new Error('Function not implemented.');
      }}
      isBtAdmin={false}
      usersEmail={''}
      isFeedback={true}
      feedbackMessage={feedbackMessage}
      setFeedbackMessage={setFeedbackMessage}
      userName={userName}
      setUserName={setUserName}
      message={responseMessage}
    />
  );
};
