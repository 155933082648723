import React, { Fragment } from 'react';
import UserRow from '../AdminPanel/UserRow';
import Search from '../Search';
import ReactLoading from 'react-loading';
import IUserList from '../../utils/UserList/UserList.interface';
import { IPPHeading } from '../../library/Heading/Heading';
import ModuleRow from './ModuleRow';
import ConditionalLoader from '../Hoc/ConditionalLoader';

/**
 * This common props takes in several parameters.
 * @data - These prop is likely used to store the modules list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @setQuery - These prop is used to update the @query based on user's input
 **/
interface ModuleListProps {
  data: any[];
  isLoading: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

// ModuleList is a functional component which displays a table of repositories that organisation has access to.
const ModuleList: React.FC<ModuleListProps> = ({ data, isLoading, query, setQuery }) => {
  return (
    <Fragment>
      <div className="w-full">
        <div className="bg-white" style={{ paddingInline: '2px 2px', width: '100%' }}>
          <IPPHeading
            headerText={'Indigo Modules'}
            className={'text-black text-xl leading-tight font-medium mb-2'}
          />
          <br />
          <hr />
          <br />
          <Search onChange={(e: any) => setQuery(e.target.value)} text="Search by access..." />
          {/* Todo - fix with next release */}
          <br />
          <br />
          <ConditionalLoader isLoading={isLoading} width={500}>
            <div className="w-full h-[300px] overflow-y-auto">
              {data.length > 0 ? (
                <table className="mx-auto max-w-4xl min-w-fit md:w-full table-fixed whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                  <thead className="bg-[#f8f8f8]">
                    <tr className="text-black text-left">
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Module Name{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Access
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Action{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {data
                      .filter((dataSet: IUserList) =>
                        dataSet?.moduleList?.some((info: any) =>
                          info.accessType.toLowerCase().includes(query.toLowerCase())
                        )
                      )
                      .map((dataSet: IUserList, index: number) => (
                        <Fragment key={index}>
                          {dataSet?.moduleList
                            ? dataSet.moduleList
                                .filter((info: any) =>
                                  info.accessType.toLowerCase().includes(query.toLowerCase())
                                )
                                .sort((a: { moduleName: string }, b: { moduleName: any }) =>
                                  a.moduleName.localeCompare(b.moduleName)
                                )
                                .slice(0, dataSet?.moduleList?.length)
                                .map((info: any) => (
                                  <ModuleRow
                                    key={info.moduleName}
                                    name={info.moduleName!}
                                    accessType={info.accessType}
                                    disabled={true}
                                  />
                                ))
                            : null}
                        </Fragment>
                      ))}
                    {data.filter((dataSet: IUserList) =>
                      dataSet?.moduleList?.some((info: any) =>
                        info.accessType.toLowerCase().includes(query.toLowerCase())
                      )
                    ).length === 0 && (
                      <tr>
                        <td className="px-6 py-4 pl-[7rem] text-center">
                          <div
                            className="px-6 py-4 text-center"
                            style={{ paddingTop: '5rem', fontWeight: 'bold' }}
                          >
                            <IPPHeading
                              headerText={'No data found ☹️ !!'}
                              className={'text-bold'}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <div
                  className="px-6 py-4 text-center"
                  style={{ paddingTop: '5rem', fontWeight: 'bold' }}
                >
                  <IPPHeading headerText={'No data found ☹️ !!'} className={'text-bold'} />
                </div>
              )}
            </div>
          </ConditionalLoader>
        </div>
      </div>
    </Fragment>
  );
};

export default ModuleList;
