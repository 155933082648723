import React, { Fragment, useContext, useEffect, useState } from 'react';
import UserList from '../components/AdminPanel/UserList';
import ModuleList from '../components/AdminPanel/ModuleList';
import { LoginContext } from '../context/IppContext';
import { FederatedIdentity } from '../utils/AccessCredentials/FederatedIdentity';
import OrganizationUser from '../utils/OrganizationUser/OrganizationUser';
import { downloadFile } from '../utils/Helper/downloadFiles';
import Downloadfile from '../library/DownloadFiles/DownloadFile';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AwsLogin from '../utils/AccessCredentials/AwsLogin';
import { AWS_SIGNIN_URL } from '../utils/Helper/helper';
import { IPPFourthLevelHeading, IPPParagraph } from '../library/Heading/Heading';
import Button from '../library/Button/button';
import IUserList from '../utils/UserList/UserList.interface';

const AdminPanel: React.FC = () => {
  // useState hook to store the timeOfDay state variable
  const { loginToken } = useContext(LoginContext);
  const [accessKey, setAccessKey] = useState<String>('');
  const [secret, setSecret] = useState<String>('');
  const [session, setSession] = useState<String>('');
  const [roleARN, setRoleArn] = useState<String>('');
  const [error, setError] = useState<string>(''); // New error state
  const parsedLoginToken = JSON.parse(loginToken);
  const userPoolId = parsedLoginToken.userPoolId;
  const identityPoolId = parsedLoginToken.IdentityPoolId;
  const federatedIdentity = new FederatedIdentity({
    userPoolId,
    identityPoolId
  });
  const navigate: NavigateFunction = useNavigate();
  const [isShowCred, setIsShowCred] = useState<boolean>(false);
  // Storing the user's name and displaying it as a welcome page
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  // To handle show functionality
  const [numValuesToShow, setNumValuesToShow] = useState('');
  // To handle change based on event on show bar.
  const handleNumValuesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setNumValuesToShow(value);
  };
  const [dataUser, setDataUser]: any = useState([]);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [queryUser, setQueryUser] = useState('');
  // Download UserGuideBook and script.
  const downloadUserGuide = () => {
    const fileUrl = '/UserGuideBook.pdf';
    const fileName = 'UserGuideBook'; // Set the desired file name
    downloadFile(fileUrl, fileName);
  };
  const downloadFileList = [
    { fileUrl: '/setup.sh', fileName: 'setup.sh' },
    { fileUrl: '/ipp_update_script.sh', fileName: 'ipp_update_script.sh' }
  ];
  const downloadScript = () => {
    for (const file of downloadFileList) {
      downloadFile(file.fileUrl, file.fileName);
    }
  };

  const userName = sessionStorage.getItem('adminFullName') ?? '';
  const orgEmailUpdated = sessionStorage.getItem('userEmail');

  const emailToUse = orgEmailUpdated ?? '';

  // This function is used to fetch federated session from backend and save it in component state
  const fetchFederatedSession = async () => {
    try {
      const idToken = parsedLoginToken.cognitoDetails.message.idToken.jwtToken;
      const role =
        parsedLoginToken.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const responseData = await federatedIdentity.fetchAWSCredentials(idToken, emailToUse, role);
      setAccessKey(responseData.body.data.Credentials?.AccessKeyId ?? '');
      setSecret(responseData.body.data.Credentials?.SecretAccessKey ?? '');
      setSession(responseData.body.data.Credentials?.SessionToken ?? '');
      setRoleArn(role);
      setIsShowCred(true);
    } catch (error) {
      setError('JSON parsing error: ' + error); // Handle JSON parsing error
    }
  };
  // this function is used to get sign-in token from backend and send it to aws federetion endpoint for console login.
  const getCredentials = async () => {
    try {
      const idToken = parsedLoginToken.cognitoDetails.message.idToken.jwtToken;
      const role =
        parsedLoginToken.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const responseData = await federatedIdentity.fetchAWSCredentials(idToken, emailToUse, role);
      const awsLoginObj = new AwsLogin({
        accessKey: responseData.body.data.Credentials?.AccessKeyId,
        secret: responseData.body.data.Credentials?.SecretAccessKey,
        session: responseData.body.data.Credentials?.SessionToken
      });
      const consolLoginStatus = await awsLoginObj.consoleLogin();
      if (consolLoginStatus.statusCode === 201) {
        const SigninToken = consolLoginStatus.body.SigninToken;
        // construct the url with signin token for aws login
        const getLoginUrl = `${AWS_SIGNIN_URL}${SigninToken}`;
        window.open(getLoginUrl, '_blank', 'noopener,noreferrer');
      }
    } catch {
      setError('error: in aws console login' + error); // Handle console login error
    }
  };

  const handleEditUser = (user: IUserList) => {
    const userIndex = dataUser.findIndex((u: IUserList) => u.email === user.email);
    if (userIndex !== -1) {
      const newUserList = [...dataUser];
      newUserList[userIndex] = user;
      setDataUser(newUserList);
    }
  };

  const handleDeleteUser = (email: string) => {
    const updatedDataUser = dataUser.filter((user: { email: any }) => user.email !== email);
    setDataUser(updatedDataUser);
  };

  // *********** UseEffect to handle delete user and create user *********** //
  const getAdminUsers = async () => {
    const organizationAdminObj = new OrganizationUser();
    const { users } = await organizationAdminObj.getAllAdminUser();
    setDataUser(users);
    setIsLoadingUser(false);
  };
  useEffect(() => {
    void getAdminUsers();
  }, []);

  // Fetching data from db and mapping it to the array. Here the promise returned is void.
  useEffect(() => {
    const getAdminUsersModules = async () => {
      const organizationAdminObj = new OrganizationUser();
      const result = await organizationAdminObj.getAllAdminUserModule();
      setData(result);
      setIsLoading(false);
    };
    void getAdminUsersModules();
  }, []);

  return (
    <Fragment>
      <div className="overflow-hidden relative block">
        <Downloadfile onGuideBook={downloadUserGuide} onScript={downloadScript} />
        <div className="flex flex-col justify-start mr-[10px] my-[10px]  p-4 border border-black-500 rounded-[5px] ">
          <Button
            onclick={getCredentials}
            className="bg-transparent w-48 hover:bg-blue-500 text-blue-700 font-semibold hover:text-black mt-2 py-2 px-2 border border-black-500 hover:border-solid rounded"
            buttonText="View in CodeCommit"
          />
          <Button
            onclick={fetchFederatedSession}
            className="bg-transparent w-48 hover:bg-blue-500 text-blue-700 font-semibold hover:text-black mt-2 py-2 px-2 border border-black-500 hover:border-solid rounded"
            buttonText="Get AWS Cred."
          />
          &nbsp;
          {isShowCred && (
            <>
              <IPPFourthLevelHeading
                id="aceeskeyheader"
                headerText={'AWS Credentials'}
                className={'text-black text-[16px] mb-1 leading-[1.2] font-bold'}
              />
              <IPPFourthLevelHeading
                id="aceeskeyheader"
                headerText={`aws_access_key_id: ${accessKey}`}
                className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
              />
              <IPPFourthLevelHeading
                id="aceeskeyheader"
                headerText={`aws_secret_access_key: ${secret}`}
                className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
              />
              <IPPParagraph
                id="aceeskeyheader"
                headerText={`aws_session_token: ${session}`}
                className={'text-black text-[16px] mb-0.5 leading-[1.2] break-words'}
              />
              <IPPFourthLevelHeading
                id="aceeskeyheader"
                headerText={`role_arn: ${roleARN}`}
                className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
              />
            </>
          )}
        </div>
        <div className="flex flex-col lg:flex-row w-full pt-8">
          <div className=" md:flex lg:flex flex-row justify-start w-full lg:w-2/3 pl-2.5 pr-5">
            <UserList
              isLoading={isLoadingUser}
              query={queryUser}
              setQuery={setQueryUser}
              users={dataUser}
              email={emailToUse}
              partnerName={userName}
              onDeleteUser={handleDeleteUser}
              onUserCreated={getAdminUsers}
              onEditUser={handleEditUser}
            />
          </div>
          <div className="md:flex lg:flex flex-row justify-start w-full lg:w-1/3 pr-2.5">
            <ModuleList data={data} isLoading={isLoading} query={query} setQuery={setQuery} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AdminPanel;
