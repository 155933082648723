import React, { MouseEventHandler, SetStateAction, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface ItemType {
  label: string;
  path: string;
  onClick?: MouseEventHandler;
}
interface Props {
  tabs?: ItemType[];
  links?: ItemType[];
}

const LeftNavPanel = ({ tabs, links }: Props) => {
  const [checked, setchecked] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.innerWidth < 900) {
      setchecked(false);
    }
  }, [window.innerWidth]);

  return (
    <>
      <input type="checkbox" className="hidden peer" checked={checked} readOnly />
      <div className="fixed h-full left-0 flex-shrink-0 max-h-full w-0 flex flex-col pt-0 bg-[#f8f8f8] box-border overflow-visible transition-all ease-in-out duration-700 shadow-lg md:shadow-none md:min-w-[60px] md:w-16 md:static peer-checked:w-80 z-10">
        <div className="flex justify-end align-middle pt-4 absolute min-w-fit min-h-fit left-full md:left-0 md:sticky top-0 md:top-2 transition-all ease-in-out duration-700">
          <button
            className="h-fit text-nowrap min-w-[40px] px-3 py-1 text-xs md:text-base bg-white text-black rounded-full hover:bg-black hover:text-white outline-none m-2 border-black border md:border-none"
            onClick={() => {
              setchecked(!checked);
            }}
          >
            {checked ? '<-' : '->'}
          </button>
        </div>
        <input type="checkbox" className="hidden peer" checked={checked} readOnly />
        <nav className="h-[82%] w-full flex flex-col justify-between min-w-[250px] overflow-hidden overflow-y-auto transition-all ease-in-out duration-500 -translate-x-60 peer-checked:translate-x-0 px-4">
          <ul className="pt-2 pb-0 w-full min-w-fit max-h-[65%] overflow-y-auto">
            {tabs?.length &&
              tabs.map((item, index) => (
                <li
                  className={`p-4 ${item.path === pathname ? 'bg-gray rounded-lg' : ''}`}
                  key={index}
                >
                  <Link to={item.path}>
                    <span className="text-black"> {item.label} </span>
                  </Link>
                </li>
              ))}
          </ul>
          <ul className="absolute bottom-0 left-0 pt-2 pb-0 w-full min-w-fit">
            {links?.length &&
              links.map((item, index) => (
                <li className="p-4" key={index}>
                  {item.onClick ? (
                    <p className="text-black cursor-pointer" onClick={item.onClick}>
                      {item.label}
                    </p>
                  ) : item.path ? (
                    <Link
                      className="text-black no-underline hover:text-iris m-0 p-0"
                      to={item.path}
                    >
                      <p className="text-black cursor-pointer p-0 m-0">{item.label}</p>
                    </Link>
                  ) : (
                    <p className="text-trans cursor-not-allowed p-0 m-0">{item.label}</p>
                  )}
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default LeftNavPanel;
