import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import ConditionalLoader from './Hoc/ConditionalLoader';

interface Props {
  title: string;
  value: number | string;
  isLoading?: boolean;
  className?: string;
}

const StatCard = ({ isLoading, title, value, className }: Props) => {
  return (
    <div
      className={`flex justify-center items-center flex-col bg-white border shadow-lg rounded-md ${
        className ?? ''
      }`}
    >
      <ConditionalLoader isLoading={isLoading} className="my-16" width={200}>
        <h2 className="text-5xl font-bold pb-2">{value}</h2>
        <p className="font-bold text-center">{title}</p>
      </ConditionalLoader>
    </div>
  );
};

export default StatCard;
