import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserType } from '../utils/Helper/enums';
import { adminRoutes, btAdminRoutes, routes, userRoutes } from '../routes/Routes';

/**
 * This method is responsible for handling protected routes within the application.
 * @admin , @btadmin and @userPanel - These three routes are being recalled as protected routes as these routes are the important part of the business aspect.
 * The variable @userType is utilized to store the current path of the user logging in, particularly when Single Sign-On (SSO) is being employed.
 * @userType - These variable is storing the current path, the user is logging in. (Note: Since we're using SSO).
 * The purpose of checking the validity of @userType is to ensure that only authorized users are permitted to access their respective designated routes.
 * In the event that @user attempts to log in as a specific user but subsequently manipulates the URL to access another protected route, the application is designed to address this scenario by redirecting the user back to the @login page.
 * This implementation significantly enhances the security of our application, as it actively prevents unauthorized access to sensitive areas and safeguards the integrity of our business processes.
 **/
export const ProtectedRoutes = () => {
  const userType = sessionStorage.getItem('user-type');
  const location = useLocation();

  const clearSession = async () => {
    await sessionStorage.clear();
  };

  if (
    !userType ||
    (!userType && location.pathname === '/register') ||
    (userType === String(UserType.OrganizationUser) &&
      (Object.values(btAdminRoutes).includes(location.pathname) ||
        Object.values(adminRoutes).includes(location.pathname))) ||
    (userType === String(UserType.OrganizationAdmin) &&
      (Object.values(btAdminRoutes).includes(location.pathname) ||
        Object.values(userRoutes).includes(location.pathname))) ||
    (userType === String(UserType.BtAdmin) &&
      (Object.values(adminRoutes).includes(location.pathname) ||
        Object.values(userRoutes).includes(location.pathname)))
  ) {
    void clearSession();
    return <Navigate to={routes.login} />;
  }

  return <Outlet />;
};
